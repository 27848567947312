@import url('https://fonts.googleapis.com/css2?family=Jura:wght@700&display=swap');

.App {
  display: flex;
  justify-content: center;
  align-items: flex-start;  /* Changed from center to flex-start */
  height: 100vh;
  background-color: #2c1a2f;
  overflow: hidden;
  position: relative;  /* Added for positioning the corner logo */
}

.wave-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  margin-top: -10vh;  /* Added negative margin to move content up */
}

.company-name {
  font-family: 'Jura', sans-serif;
  font-size: 102px;  /* Increased from 96px to 192px */
  color: #ffffff;
  font-weight: 700;
  text-shadow: 4px 4px 8px rgba(0,0,0,0.3);  /* Increased shadow for better visibility */
  margin-bottom: 40px;  /* Increased from 20px to 40px */
  text-align: center;  /* Ensure text is centered if it wraps */
  line-height: 1.2;  /* Adjust line height for better spacing if text wraps */
}

.email-link {
  font-family: 'Jura', sans-serif;
  font-size: 24px;  /* Increased from 18px to 24px for better proportion */
  color: #e0e0e0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.email-link:hover {
  color: #ffffff;
  text-decoration: underline;
}

.wave {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle at top left,
    rgba(230, 190, 255, 0.5) 0%,
    rgba(200, 150, 230, 0.4) 25%,
    rgba(160, 100, 200, 0.3) 50%,
    rgba(120, 60, 170, 0.2) 75%,
    rgba(80, 20, 140, 0.1) 100%
  );
  animation: pulse 4s ease-in-out infinite;
  z-index: 1;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(0.5);
    opacity: 0.1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.7;
  }
}

.corner-logo {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 100px;  /* Adjust size as needed */
  height: auto;
  z-index: 1000;  /* Ensure it's above all other elements */
}

/* Media Queries for Responsive Design */

@media screen and (max-width: 1024px) {
  .company-name {
    font-size: 130px;
  }
  .email-link {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .company-name {
    font-size: 90px;
  }
  .email-link {
    font-size: 18px;
  }
  .corner-logo {
    width: 80px;
  }
}

@media screen and (max-width: 480px) {
  .company-name {
    font-size: 50px;
    margin-bottom: 20px;
  }
  .email-link {
    font-size: 16px;
  }
  .corner-logo {
    width: 60px;
    bottom: 10px;
    right: 10px;
  }
  .content {
    margin-top: 0;
  }
}

@media screen and (max-height: 600px) {
  .content {
    margin-top: 0;
  }
  .company-name {
    font-size: 50px;
    margin-bottom: 20px;
  }
}
